import Vue from 'vue'
import router  from '@/router'
import App from '@/App.vue'
import '@/permission' // permission control

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
