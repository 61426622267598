import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const routes = [
    {
        path: '/',
        component: () => import('@/views/index'),
        meta:{title:'首页'}
    },
    {
        path: '/rootPassword',
        component: () => import('@/views/rootPassword'),
        meta:{title:'修改母码'}
    },
    {
        path: '/card',
        component: () => import('@/views/card'),
        meta:{title:'卡管理'}
    },
    {
        path: '/card/card1',
        component: () => import('@/views/card1'),
        meta:{title:'卡管理-登记卡'}
    },
    {
        path: '/card/card2',
        component: () => import('@/views/card2'),
        meta:{title:'卡管理-用户号删除卡'}
    },
    {
        path: '/card/card3',
        component: () => import('@/views/card3'),
        meta:{title:'卡管理-删除全部卡'}
    },
    {
        path: '/password',
        component: () => import('@/views/password'),
        meta:{title:'密码管理'}
    },
    {
        path: '/password/password1',
        component: () => import('@/views/password1'),
        meta:{title:'密码管理-登记密码'}
    },
    {
        path: '/password/password2',
        component: () => import('@/views/password2'),
        meta:{title:'密码管理-用户号删除密码'}
    },
    {
        path: '/password/password3',
        component: () => import('@/views/password3'),
        meta:{title:'密码管理-删除全部密码'}
    },
    {
        path: '/fingerprint',
        component: () => import('@/views/fingerprint'),
        meta:{title:'指纹管理'}
    },
    {
        path: '/fingerprint/finger1',
        component: () => import('@/views/finger1'),
        meta:{title:'指纹管理-随意登记指纹'}
    },
    {
        path: '/fingerprint/finger2',
        component: () => import('@/views/finger2'),
        meta:{title:'指纹管理-用户号登记指纹'}
    },
    {
        path: '/fingerprint/finger3',
        component: () => import('@/views/finger3'),
        meta:{title:'指纹管理-用户号删除指纹'}
    },
    {
        path: '/fingerprint/finger4',
        component: () => import('@/views/finger4'),
        meta:{title:'指纹管理-手指删除指纹'}
    },
    {
        path: '/fingerprint/finger5',
        component: () => import('@/views/finger5'),
        meta:{title:'指纹管理-删除全部指纹'}
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
        meta:{title:'404'}
    },
    { path: '*', redirect: '/404' }
]

const router= new Router({
    routes
})
export default router
