<template>
  <div class="house">
    <h1 class="tit">keylock #6600-92 92A操作手册</h1>
    <router-view>
    </router-view>
  </div>

</template>

<script>

export default {
    name: 'house',
    components: {},
    data() {
        return {

        }
    },
    watch: {
      $route() {
      }
    },
    methods: {},
    computed: {}
}
</script>
